<template>
  <div class="meeting-detail wrapper page-wrap" v-if="meeting">
    <a-spin :spinning="loading">
      <div class="detail-top flex">
        <img :src="meeting.meeting_image" />
        <div class="detail-list">
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <span>房间名称</span>
            </div>
            <div class="list-content">
              {{ meeting.meeting_name }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <span>房间地址</span>
            </div>
            <div class="list-content">
              {{ meeting.meeting_address }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <span>房间管理员</span>
            </div>
            <div class="list-content">
              {{ meeting.meeting_contact_name }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <span>房间联系电话</span>
            </div>
            <div class="list-content">
              {{ meeting.meeting_contact_phone }}
            </div>
          </div>
          <div class="flex flex-between a-list">
            <div class="list-title flex">
              <span>预订说明</span>
            </div>
            <div class="list-content">
              {{ meeting.meeting_introduction }}
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div style="padding-bottom: 50px" v-if="meeting_book">
        <div class="book-title">最近预订（{{ meeting_book.length }}）</div>
        <div class="flex flex-between meeting-box-main">
          <template v-for="(item, index) in meeting_book" :key="index">
            <div class="meeting-item flex flex-between">
              <div class="metting-info">
                <div class="flex">
                  <span class="font-18 font-bold">{{ item.user_phone }}</span>
                  <span
                    :class="{
                      book_status: true,
                      'label-gray': item.book_status == -1
                    }"
                    >{{ item.book_status == 1 ? "预订成功" : "取消成功" }}</span
                  >
                </div>
                <div class="mt-10 font-16">
                  <span class="text-black" style="margin-right:10px"> {{ item.book_date }}</span
                  ><span
                    >{{ item.book_start_time }}-{{ item.book_end_time }}</span
                  >
                </div>
              </div>
              <!-- <right-outlined /> -->
            </div>
          </template>
        </div>
      </div>
      <a-button class="btn" block size="large" type="primary" @click="goToApply"
        >开始预订</a-button
      >
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getMeetingData } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
// import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "MeetingDetail",
  components: {
    // RightOutlined
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pageData = reactive({
      meeting: null,
      meetingId: null,
      loading: true,
      userData: computed(() => getUserData()),
      meeting_book: null
    });
    const getMeetingDataFun = _meetingId => {
      getMeetingData({
        meeting_id: _meetingId,
        park_id: pageData.userData?.user_park_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.meeting = res.data.meeting;
            pageData.meeting_book = res.data.meeting_book;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const goToApply = () => {
      url.navigateTo("/meeting/apply", {
        t: encode(`${pageData.meeting.meeting_id}`)
      });
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/meeting/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.meetingId = query[0];
            getMeetingDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.meeting = null;
            message.error("暂无会议室信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      goToApply
    };
  }
});
</script>
<style lang="less" scoped>
.meeting-detail {
  .detail-top {
    margin-top: 40px;
    margin-bottom: 50px;
    align-items: flex-start;
    > img {
      width: 520px;
      height: 300px;
      background-color: #1276cb;
      border-radius: 10px;
    }
    .detail-list {
      margin-left: 40px;
      width: 590px;
      .h1 {
        font-size: 24px;
        color: #212531;
        margin-bottom: 30px;
      }
      .a-list {
        // height: 56px;
        padding: 15px 0;
        border-bottom: 1px solid #dfdfdf;
        font-size: 18px;
        color: #212531;
        .list-title {
          img {
            margin-right: 10px;
          }
        }
        .list-content {
          width: 70%;
          text-align: right;
        }
      }
    }
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
  }
  .book-title {
    position: relative;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 20px;
    color: #212531;
    border-bottom: 1px solid #f6f8f9;
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #1276cb;
      border-radius: 10px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .meeting-box-main {
    flex-wrap: wrap;
    .meeting-item {
      width: 570px;
      padding: 30px 0;
      border-bottom: 2px solid #dfdfdf;
      cursor: pointer;

      .metting-info {
        .book_status {
          width: 81px;
          height: 23px;
          line-height: 23px;
          border-radius: 11px;
          border: solid 1px #1276cb;
          color: #1276cb;
          font-size: 14px;
          text-align: center;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
